import * as React from "react"

import { SEO } from "../components/seo"
import { PrimaryHeader } from "../components/header"
import { Footer } from "../components/Footer"
import { clearSnappy } from "../model/helpers"

const Privacy = () => (
  <>
    {clearSnappy()}
    <PrimaryHeader />
    <SEO isQuiz={true} title="Privacy Policy" url="privacy" />
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="body-wrap">
            <div className="row">
              <div className="col-sm-12 listicle-copy">
                <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 listicle-copy">
                <Title>Privacy Policy</Title>
                <p>
                  Protecting your private information is our priority. This Statement of Privacy applies to Grey State
                  Ventures LLC, https://triviaboss.com, Trivia Boss, https://trivianerds.com, and TriviaNerds, and
                  governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted,
                  all references to Grey State Ventures LLC include https://triviaboss.com and https://trivianerds.com.
                  The Grey State Ventures LLC website is a Trivia Website. By using the Grey State Ventures LLC website,
                  you consent to the data practices described in this statement.
                </p>

                <Title>Collection of your Personal Information</Title>
                <p>
                  In order to better provide you with products and services offered on our Site, Grey State Ventures LLC
                  may collect personally identifiable information, such as your Name, Email Address, Mailing Address, IP
                  address, Phone Number and more. We do not collect any personal information about you unless you
                  voluntarily provide it to us. However, you may be required to provide certain personal information to
                  us when you elect to use certain products or services available on the Site. These may include:
                </p>

                <p>
                  (a) registering for an account on our Site
                  <br />
                  (b) entering a sweepstakes or contest sponsored by us or one of our partners
                  <br />
                  (c) signing up for special offers from selected third parties
                  <br />
                  (d) sending us an email message
                  <br />
                  (e) submitting your credit card or other payment information when ordering and purchasing products and
                  services on our Site. To wit, we will use your information for, but not limited to, communicating with
                  you in relation to services and/or products you have requested from us. We also may gather additional
                  personal or non-personal information in the future.
                </p>

                <Title>Use of your Personal Information</Title>
                <p>
                  Grey State Ventures LLC collects and uses your personal information to operate its website(s) and
                  deliver the services you have requested. Grey State Ventures LLC may also use your personally
                  identifiable information to inform you of other products or services available from Grey State
                  Ventures LLC and its partners and affiliates.
                </p>

                <Title>Sharing Information with Third Parties</Title>
                <p>
                  Grey State Ventures LLC does not sell, rent or lease its customer lists to third parties. Grey State
                  Ventures LLC does not share your personal information with third parties without your consent, except
                  in the following circumstances and as otherwise described in this Privacy Policy.
                </p>

                <Title>Service Providers</Title>
                <p>
                  Grey State Ventures LLC may share your personal information with third party companies and individuals
                  that provide services on our behalf or help us operate the Services (such as customer support,
                  hosting, analytics, email delivery, marketing, and database management services). These third parties
                  may use your personal information only as directed or authorized by us and in a manner consistent with
                  this Privacy Policy, and are prohibited from using or disclosing your information for any other
                  purpose.
                </p>

                <Title>Partners</Title>
                <p>
                  Grey State Ventures LLC may share your personal information with partners that we work with or enable
                  partners to collect information directly via our Services. For example, Grey State Ventures LLC works
                  with Google to deliver advertisements. Grey State Ventures LLC may also share your personal
                  information with other partners in order to provide you with content and other features through the
                  Services, and such partners may send you promotional materials or otherwise contact you regarding
                  products and services that they offer. When you choose to enter a contest or sign up for a
                  sweepstakes, Grey State Ventures LLC may share the personal information you provide as part of the
                  offer with the named co-sponsors or other third parties affiliated with such offer. Grey State
                  Ventures LLC may also share your personal information with trusted third-party partners for the
                  partners’ marketing, advertising, or other purposes, such as when Grey State Ventures LLC shares
                  personal information with advertising partners to facilitate personalized ad delivery based on your
                  activity on the Services and on other sites or services. Third-party platforms and social media
                  networks. If you have enabled features or functionality that connect the Services to a third-party
                  platform or social media network (such as by logging in to the Services using your account with the
                  third-party, providing your API key or similar access token for the Services to a third-party, or
                  otherwise linking your account with the Services to a third-party’s services), we may disclose the
                  personal information that you authorized Grey State Ventures LLC to share. Grey State Ventures LLC
                  does not control the third party’s use of your personal information.
                </p>
                <p>
                  When you use our website, we share information that we collect from you, such as your email (in hashed
                  form), IP address or information about your browser or operating system, with our partner/service
                  provider, LiveRamp Inc. LiveRamp returns an online identification code that we may store in our
                  first-party cookie for our use in online and cross-channel advertising and it may be shared with
                  advertising companies to enable interest-based and targeted advertising. To opt out of this use,
                  please{" "}
                  <a href="https://optout.liveramp.com/opt_out" target="_blank">
                    click here
                  </a>
                  .
                </p>

                <Title>Other Users of the Services and the Public</Title>
                <p>
                  Grey State Ventures LLC may provide functionality that enables you to disclose personal information to
                  other users of the Services or the public. For instance, you may be able to maintain a user profile
                  with information about yourself or your use of the Services that you can make available to other users
                  or the public. You may also be able to submit content to the Services (such as comments, questions,
                  stories, reviews, surveys, blogs, photos, and videos), and Grey State Ventures LLC will identify you
                  by displaying information such as your name, username, social media handle, or a link to your user
                  profile along with the content you submit. Grey State Ventures LLC does not control how other users or
                  third parties use any personal information that you make available to other users or the public.
                </p>

                <Title>Affiliates</Title>
                <p>
                  Grey State Ventures LLC may share your personal information with our subsidiaries and affiliates, for
                  purposes consistent with this Privacy Policy.
                </p>

                <Title>Professional advisors</Title>
                <p>
                  Grey State Ventures LLC may disclose your personal information to professional advisors, such as
                  lawyers, bankers, auditors and insurers, where necessary in the course of the professional services
                  that they render to us.For compliance, fraud prevention and safety. Grey State Ventures LLC may share
                  your personal information for the compliance, fraud prevention and safety purposes as described above.
                </p>
                <p>
                  Grey State Ventures LLC may disclose your personal information, without notice, if required to do so
                  by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the
                  law or comply with legal process served on Grey State Ventures LLC or the site; (b) protect and defend
                  the rights or property of Grey State Ventures LLC; and/or (c) act under exigent circumstances to
                  protect the personal safety of users of Grey State Ventures LLC, or the public.
                </p>

                <Title>Business transfers</Title>
                <p>
                  Grey State Ventures LLC may sell, transfer or otherwise share some or all of our business or assets,
                  including your personal information, in connection with a business transaction (or potential business
                  transaction) such as a corporate divestiture, merger, consolidation, acquisition, joint venture,
                  reorganization or sale of assets, or in the event of bankruptcy or dissolution.
                </p>

                <Title>Automatically Collected Information</Title>
                <p>
                  Information about your computer hardware and software may be automatically collected by Grey State
                  Ventures LLC. This information can include: your IP address, browser type, domain names, access times
                  and referring website addresses. This information is used for the operation of the service, to
                  maintain quality of the service, and to provide general statistics regarding use of the Grey State
                  Ventures LLC website. Some of our business partners may use cookies on our site (for example,
                  advertisers). However, we have no access to or control over these cookies.
                </p>

                <Title>Links</Title>
                <p>
                  This website contains links to other sites. Please be aware that Grey State Ventures LLC is not
                  responsible for the content or privacy practices of such other sites. We encourage our users to be
                  aware when they leave our site and to read the privacy statements of any other site that collects
                  personally identifiable information.
                </p>

                <Title>Children Under Thirteen</Title>
                <p>
                  Grey State Ventures LLC does not knowingly collect personally identifiable information from children
                  under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian
                  for permission to use this website.
                </p>

                <Title>Opt-Out & Unsubscribe from Third Party Communications</Title>
                <p>
                  We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain
                  information. Users may opt-out of receiving any or all communications from third-party partners of
                  Grey State Ventures LLC by contacting us at info@triviaboss.com
                </p>

                <Title>E-mail Communications</Title>
                <p>
                  From time to time, Grey State Ventures LLC may contact you via email for the purpose of providing
                  announcements, sweepstakes offers, promotional offers, alerts, confirmations, surveys, and/or other
                  general communication. In order to improve our Services, we may receive a notification when you open
                  an email from Grey State Ventures LLC or click on a link therein. If you would like to stop receiving
                  marketing or promotional communications via email from Grey State Ventures LLC, you may opt out of
                  such communications by emailing an opt-out request to info@triviaboss.com.
                </p>

                <Title>External Data Storage Sites</Title>
                <p>
                  We may store your data on servers provided by third party hosting vendors with whom we have
                  contracted.
                </p>

                <Title>Changes to this Statement</Title>
                <p>
                  Grey State Ventures LLC reserves the right to change this Privacy Policy from time to time. We will
                  notify you about significant changes in the way we treat personal information by sending a notice to
                  the primary email address specified in your account, by placing a prominent notice on our site, and/or
                  by updating any privacy information on this page. Your continued use of the Site and/or Services
                  available through this Site after such modifications will constitute your: (a) acknowledgment of the
                  modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
                </p>

                <Title>Contact Information</Title>
                <p>
                  Grey State Ventures LLC welcomes your questions or comments regarding this Statement of Privacy. If
                  you believe that Grey State Ventures LLC has not adhered to this Statement, please contact Grey State
                  Ventures LLC at:
                </p>
                <p />
                <div>Grey State Ventures LLC</div>
                <p />
                <div>108 WEST 13TH ST</div>
                <div>Wilmington, Delaware 19801</div>
                <p />
                <div>Email Address:</div>
                <div>info@triviaboss.com</div>
                <p />
                <p />
                <div>Telephone number:</div>
                <div>(404) 618-0198</div>
                <p />
                <p>Effective as of August 5, 2020</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
)

export default Privacy

const Title: React.FC = ({ children }) => (
  <div>
    <b>{children}</b>
    <b />
  </div>
)
